<template>
  <b-sidebar
    id="add-new-regionsidebar"
    :visible="isAddNewRegionSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-region-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Region
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
            ref="refFormObserver"
            #default="{invalid}"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="addNewRegion"
          @reset.prevent="resetForm"
        >
          <!-- Kota -->
          <validation-provider
            #default="validationContext"
            name="Region Name"
            rules="required"
          >
            <b-form-group
              label="Region Name"
              label-for="Region Name"
            >
              <b-form-input
                id="RegionName"
                v-model="RegionName"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Region Name"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
           <!-- Region Code -->
          <validation-provider
            #default="validationContext"
            name="Region Code"
            rules="required"
          >
            <b-form-group
              label="Region Code"
              label-for="RegionCode"
            >
              <b-form-input
                id="RegionCode"
                v-model="RegionCode"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Region Code"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
                block
                :disabled="invalid && submitButtonDisabled"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import { ref } from '@vue/composition-api'

import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import useRegionsList from './useRegionsList'

const RegionName = ''
const RegionCode = ''
const regions = []
const submitButtonDisabled = false
export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewRegionSidebarActive',
    event: 'update:is-add-new-region-sidebar-active',
  },
  props: {
    isAddNewRegionSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      submitButtonDisabled,
      regions,
      required,
      alphaNum,
      RegionName,
      RegionCode,
    }
  },
  setup() {
    const blankRegionData = {
      RegionName: '',
      RegionCode: '',
    }
    const unitData = ref(blankRegionData)
    const resetunitData = () => {
      unitData.value = blankRegionData
    }

    const onSubmit = () => {
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetunitData)

    return {
      RegionName,
      RegionCode,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  mounted() {
    this.getRegions()
  },
  methods: {
    addNewRegion() {
      this.submitButtonDisabled = true
      const userToken = this.$cookies.get('userToken')
const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${userToken}`,
}
        const payload = {
          region_name: this.RegionName,
          region_code: this.RegionCode,
            time: moment(),
          }
          axios
          .post(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_ADD_REGION}`, payload, { headers })
          .then(response => {
            this.submitButtonDisabled = false
              // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
          if (response.data.success === true) {
                this.$root.$emit('refreshTable', 'refreshTable')
                this.isAddNewRegionSidebarActive = false
                this.RegionName = ""
                this.RegionCode = ""
                // this.$parent.refRegionListTable.refresh()
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Congratulation',
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: 'Your data has been inserted!',
                  },
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Notification',
                    icon: 'BellIcon',
                    variant: 'danger',
                    text: 'Something went wrong',
                  },
                })
              }
              }).catch(error => {
                console.log(error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Notification',
                    icon: 'BellIcon',
                    variant: 'danger',
                    text: 'Something went wrong',
                  },
                })
              })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-region-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
