<template>

  <div>

    <region-list-add-new
      :is-add-new-region-sidebar-active.sync="isAddNewRegionSidebarActive"
      :role-options="roleOptions"
      @refetch-data="refetchData"
    />
    <region-list-edit
      :is-edit-region-sidebar-active.sync="isEditRegionSidebarActive"
      :region-id.sync="RegionID"
      :region-name.sync="RegionName"
      :region-code.sync="RegionCode"
      :region-city.sync="RegionCity"
      :region-address.sync="RegionAddress"
      :region-phone.sync="RegionPhone"
      :region-fax.sync="RegionFax"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                variant="primary"
                @click="isAddNewRegionSidebarActive = true"
              >
                <span class="text-nowrap">Add Region</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refRegionListTable"
        class="position-relative"
        :items="fetchRegions"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: Region Name -->
        <template #cell(RegionName)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.region_name }}</span>
          </div>
        </template>

        <!-- Column: Region Code -->
        <template #cell(RegionCode)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.region_code }}</span>
          </div>
        </template>

        <!-- Column: Region City -->
        <template #cell(RegionCity)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.region_city }}</span>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="editRegion(  data.item ); isEditRegionSidebarActive = true;">
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="deleteRegion( data.item.region_id )">
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRegions"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import axios from 'axios'
import vSelect from 'vue-select'
import store from '@/store'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
// import RegionesListFilters from './RegionesListFilters.vue'
import RegionList from './RegionList'
import regionModule from '../regionModule'
import RegionListAddNew from './RegionAddNew.vue'
import RegionListEdit from './RegionEdit.vue'

const userRole = ''
const RegionData = null
export default {
  components: {
    // RegionesListFilters,
    RegionListEdit,
    RegionListAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  mounted() {
    this.userRole = this.$cookies.get('UserRole')
    this.$root.$on('refreshTable', () => {
    this.$refs.refRegionListTable.refresh()
  })
  },
  setup() {
    const REGION_APP_STORE_MODULE_NAME = 'app-unit'

    // Register module
    if (!store.hasModule(REGION_APP_STORE_MODULE_NAME)) store.registerModule(REGION_APP_STORE_MODULE_NAME, regionModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REGION_APP_STORE_MODULE_NAME)) store.unregisterModule(REGION_APP_STORE_MODULE_NAME)
    })

    const isAddNewRegionSidebarActive = ref(false)
    const isEditRegionSidebarActive = ref(false)
    const RegionID = ref('')
    const RegionName = ref('')
    const RegionCode = ref('')
    const RegionCity = ref('')
    const RegionAddress = ref('')
    const RegionPhone = ref('')
    const RegionFax = ref('')
    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const {
      fetchRegions,
      fetchRoles,
      tableColumns,
      perPage,
      currentPage,
      totalRegions,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refRegionListTable,
      refetchData,

      // UI
      resolveRegionRoleVariant,
      resolveRegionRoleIcon,
      resolveRegionStatusVariant,

      // Extra Filters
      roleFilter,
      statusFilter,
    } = RegionList()

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]
    return {

      // Sidebar
      RegionID,
      RegionName,
      RegionCode,
      RegionCity,
      RegionAddress,
      RegionPhone,
      RegionFax,
      isEditRegionSidebarActive,
      isAddNewRegionSidebarActive,
      userRole,
      RegionData,
      fetchRegions,
      fetchRoles,
      tableColumns,
      perPage,
      currentPage,
      totalRegions,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refRegionListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveRegionRoleVariant,
      resolveRegionRoleIcon,
      resolveRegionStatusVariant,

      roleOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      statusFilter,
    }
  },
  methods: {
      deleteRegion(id) {
        const userToken = this.$cookies.get('userToken')
const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${userToken}`,
}
        const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_DELETE_REGION}/${id}`
          axios
              .get(url, { headers })
              .then(response => {
                console.log(response)
                if (response.data.success === true) {
                  this.$refs.refRegionListTable.refresh()
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Congratulation',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: 'Your data has been deleted!',
                    },
                  })
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Notification',
                      icon: 'BellIcon',
                      variant: 'danger',
                      text: 'Something went wrong',
                    },
                  })
                }
              })
              .catch(err => {
                  console.log(err)
              })
      },
      editRegion(data) {
        const parsed = data
        this.RegionID = parsed.region_id.toString()
        this.RegionName = parsed.region_name
        this.RegionCode = parsed.region_code
        this.RegionCity = parsed.region_city
        this.RegionAddress = parsed.region_address
        this.RegionPhone = parsed.region_phone
        this.RegionFax = parsed.region_fax
      },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
